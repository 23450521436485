<template>
  <div class="set-point">
    <!-- 测点信息 -->
    <el-form :model="form1" :inline="true" :rules="rule1" ref="form1">
      <div class="formMain">
        <div class="formMain-title">
          <img style="margin-right: 12px" src="@/assets/images/fragment-title-icon.png" />
          <div>测点信息</div>
        </div>
        <div class="formMain-content box center flexW">
          <el-form-item class="long-input" label="测点编号" prop="code">
            <el-input v-model="form1.code" placeholder="请输入测点编号" clearable />
          </el-form-item>
          <el-form-item label="位置描述" class="long-input" prop="site">
            <el-input v-model="form1.site" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="监测类型" prop="typeGroupId">
            <el-select v-model="form1.typeGroupId" placeholder="请选择" @change="getTargetOptions">
              <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="监测指标" prop="typeId">
            <el-select v-model="form1.typeId" placeholder="请选择" @change="getPoints">
              <el-option v-for="item in targetOptions" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>

          <el-form-item label="采样时间间隔" prop="frequency" style="position: relative">
            <el-input v-model="form1.frequency" placeholder="请输入" min="1" clearable> <span slot="suffix">秒</span> </el-input>
          </el-form-item>

          <!-- <el-form-item label="状况评估" prop="healthAssess">
        <el-select v-model="form1.healthAssess" placeholder="请选择" @change="getHealthOptions">
          <el-option v-for="item in healthOptions" :key="item.id" :label="item.title" :value="item.id" />
        </el-select>
      </el-form-item> -->

          <!-- 新添加 测点名称 -->
          <el-form-item label="测点名称" class="long-input" prop="name">
            <el-input v-model.number="form1.name" placeholder="请输入" clearable />
          </el-form-item>
          <!-- 所属系统 -->
          <el-form-item label="所属系统" class="long-input" prop="systemId">
            <el-select v-model="form1.systemId" placeholder="请选择" @change="selectSys">
              <el-option v-for="item in sysList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="监测内容" class="long-input" prop="contentId" >
            <el-select v-model="form1.contentId" placeholder="请选择">
              <el-option v-for="item in contentList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <!-- 健康评估系数 /[^\d|.]/g  oninput="value=value.replace(/^(([^0][0-9]+|0)\.([0-9]{1,2})$))|^([^0][0-9]+|0)$/,'')"   ^-?\\d{1,9}(\\.\\d{1,2})?$  -->
          <el-form-item label="状况评估系数" class="long-input" prop="healthCoefficient">
            <el-input v-model="form1.healthCoefficient" placeholder="请输入" clearable />
          </el-form-item>

          <!-- <el-form-item label="数据类型" prop="staticDynamicSelected">
        <el-select v-model="form1.staticDynamicSelected" placeholder="请选择" @change="selectDataType">
          <el-option v-for="item in dataTypeOpitons" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item> -->
          <el-form-item label="传感器型号" prop="model">
            <el-input v-model="form1.model" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="系数" prop="coefficient">
            <el-input v-model="form1.coefficient" placeholder="请输入" clearable />
          </el-form-item>
          <!-- <el-form-item label="单位" prop="unit">
        <el-input v-model="form1.unit" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="精度" prop="precisions">
        <el-input v-model="form1.precisions" placeholder="请输入" clearable />
      </el-form-item> -->
          <el-form-item label="通讯地址" class="long-input" prop="serialNumber">
            <el-input v-model.number="form1.serialNumber" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="坐标" class="long-input long-input1" prop="coordinate">
            <slot name="label">
              <el-tooltip  class="tooltip11" effect="dark" content="用于GIS地图测点定位，输入格式为 x坐标,y坐标,z坐标" placement="bottom">
                <div>
                </div>
              </el-tooltip>
            </slot>
            <el-input v-model="form1.coordinate" placeholder="请输入" clearable />
            
          </el-form-item>

          <el-form-item label="精度" prop="precisions">
            <el-select v-model="form1.precisions" placeholder="请选择" @change="getAccuracyOptions">
              <el-option v-for="item in accuracyOptions" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>

          <el-form-item label="安装时间" class="long-input anzhuang-item" prop="installTime" @change="changeTime">
            <el-date-picker class="install-time-warp" v-model="form1.installTime" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
          </el-form-item>

          <el-form-item label="位置分组" class="long-input" prop="siteGroup">
            <el-input v-model="form1.siteGroup" placeholder="请输入" clearable />
          </el-form-item>

          <!-- 加速度的时候需要加xyz  v-if="form1.typeId == '1008'"-->
          <el-form-item label="方向选择" prop="" v-if="!disabled1">
            <el-checkbox-group v-model="form1.typeRel1" @change="changeType">
              <el-checkbox v-for="(item, index) in xyzList" :label="item.id" :key="index" @change="changexyz" :disabled="disabled1">{{ item.name }} </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- <el-form-item label="" prop="">
              <el-input style="visibility: hidden;"></el-input>
        </el-form-item> -->

          <!-- <el-form-item label="自选状态" prop="selectedRel">
        <el-select v-model="form1.selectedRel" placeholder="请选择">
          <el-option label="是" :value="1" />
          <el-option label="否" :value="0" />
        </el-select>
      </el-form-item> -->

          <!-- <el-form-item label="衍生指标">
        <el-select v-model="form1.derive" placeholder="请选择" @change="selectDeriveTarget">
          <el-option label="是" :value="1" />
          <el-option label="否" :value="0" />
        </el-select>
      </el-form-item> -->
        </div>
      </div>
    </el-form>
    <el-form :model="ruleForm" :rules="ruleFormRules" ref="ruleForm">
      <div class="formMain">
        <div class="formMain-title">
          <img style="margin-right: 12px" src="@/assets/images/fragment-title-icon.png" />
          <div>其他信息</div>
        </div>
        <div class="formMain-content box center flexW">
          <div class="el-form-item docItemMain">
            <div class="el-form-item__label requiredTip box center horizontal"><span>封面</span></div>
            <el-form-item class="img-item" prop="imagesId">
              <el-upload
                :class="isDisabled ? 'hideUpload' : ''"
                :disabled="isDisabled"
                :action="upBridgeImgUrl"
                :headers="uploadHeaders"
                multiple
                :limit="limitNum"
                :file-list="bridgeImgList"
                :on-change="handleAddChange"
                :on-exceed="handleExceed"
                list-type="picture-card"
                :on-remove="handleAddRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>

    <div class="btns box around">
      <!-- <el-button @click="handleSave">保存</el-button>
      <el-button @click="$router.go(-1)">返回</el-button> -->
      <div class="box">
        <div class="btnSelect2 mR20 pointer" @click="handleSave" style="width: 100px">保存</div>
        <div class="btnSelect1 pointer" @click="$router.go(-1)" style="width: 100px">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkedSerialNumber = (rule, value, callback) => {
      if (value) {
        if (!Number(value)) callback("请输入数字");
      } else {
        callback();
      }
    };
    var checkedNumber = (rule, value, callback) => {
      if (value === "") {
        callback("请输入");
      } else if (value == 0) {
        callback("请输入大于0的数字");
      } else if (!(Number(value) > -1)) {
        callback("请输入数字");
      } else {
        callback();
      }
    };
    var checkedTrendDuration = (rule, value, callback) => {
      if (value === "") {
        callback("请输入");
      } else if (value == 0) {
        callback("请输入大于0的数字");
      } else if (!(Number(value) > -1)) {
        callback("请输入数字");
      } else if (Number(value) > 24) {
        callback("不能超过24小时");
      } else {
        callback();
      }
    };
    var checkedMinNumb = (rule, value, callback) => {
      if (value === "") {
        callback("请输入");
      } else if (value == 0) {
        callback("必须大于0");
      } else if (!Number(value)) {
        callback("请输入数字");
      } else if (Number(value) > 10) {
        callback("必须小于10");
      } else if (parseInt(this.formData.max) && Number(value) > parseInt(this.formData.max)) {
        callback("必须小于最大阶乘");
      } else callback();
    };
    var checkedMaxNumb = (rule, value, callback) => {
      if (value === "") {
        callback("请输入");
      } else if (value == 0) {
        callback("必须大于0");
      } else if (!Number(value)) {
        callback("请输入数字");
      } else if (Number(value) > 10) {
        callback("不能大于10");
      } else if (parseInt(this.formData.min) && Number(value) < parseInt(this.formData.min)) {
        callback("必须大于最小阶乘");
      } else callback();
    };
    var checkedForwardLowerLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值下限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (parseInt(value) > parseInt(this.form10.cupper)) {
        callback("下限不能大于上限");
      } else {
        callback();
      }
    };
    var checkedForwardYellowUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (parseInt(value) < parseInt(this.form10.clower)) {
        callback("上限不能小于下限");
      } else {
        callback();
      }
    };
    var checkedForwardOrangeUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (parseInt(value) < parseInt(this.form10.cupper)) {
        callback("上限不能小于下限");
      } else {
        callback();
      }
    };
    var checkedNegativeYellowLowerLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值下限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (!(parseInt(value) < parseInt(this.form10.fupper))) {
        callback("下限必须小于上限");
      } else {
        callback();
      }
    };
    var checkedNegativeYellowUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (!(parseInt(value) > parseInt(this.form10.flower))) {
        callback("上限必须大于下限");
      } else {
        callback();
      }
    };
    var checkedNegativeOrangeLowerLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (!(parseInt(value) < parseInt(this.form10.flower))) {
        callback("下限必须小于上限");
      } else {
        callback();
      }
    };
    let checkedCoeffici = (rule, value, callback) => {
      if (value === "") {
        callback("请输入状况评估系数");
      } else if (!Number(value)) {
        callback("状况评估系数必须为数字");
      } else {
        if (value.indexOf(".") != -1) {
          let n = value.substring(value.indexOf(".")).length;
          if (n > 7) {
            callback("小数最多为6位");
            return;
          }
        }
        callback();
      }
    };
    let checkUpload = (rule, value, callback) => {
      if (!this.bridgeImgList || !this.bridgeImgList.length > 0) {
        callback("请上传封面图片");
        return;
      }
      callback();
    };
    return {
      // xyz列表
      xyzList: [
        { id: "1", name: "X" },
        { id: "2", name: "Y" },
        { id: "3", name: "Z" },
      ],
      // 系统列表
      sysList: [],
      //内容列表
      contentList: [],
      disabled1: true,
      currentPage: 1,
      ruleForm: {
        imagesId: "",
      },
      // 上传图片
      bridgeImgList: [],
      upBridgeImgUrl: "",
      uploadHeaders: {},
      limitNum: 1,
      isDisabled: false,
      healthOptions: [
        {
          id: 1,
          title: "开",
        },
        {
          id: 0,
          title: "关",
        },
      ],
      isFirstEdit: 0, // 标记第一次进入编辑页面，1-->为第一次
      targetId: "",
      projectId: "",
      patchMethod: "",
      trendForm: { trendType: 0 },
      healthAssessFlag: false,
      form1: {
        precisions: "5",
        typeId: "",
        name: "",
        installTime: "",
        typeRel1: [],
        typeRel: "",
        selectedRel: 0,
        derive: 0,
        typeGroupId: "",
        healthCoefficient: "",
        // staticDynamicSelected: 1,
        healthAssess: 0,
        coordinate: "",
        siteGroup: "",
        contentId: "",
      },
      deriveForm: {},
      form2: { patchSelected: 0 },
      form3: { outlierSelected: 0 },
      form4: {
        selectedArr: [],
        denoisingSelected: 0,
        denoisingBoxSelected: 0,
        denoisingNotchSelected: 0,
        denoisingLowpassSelected: 0,
        denoisingLowpassThreshold: "",
        denoisingNotchThreshold: "",
        denoisingDuration: "",
        denoisingBoxWidth: "",
      },
      form5: { baselineSelected: 0, baselineType: 1 },
      form6: { staticDynamicSeparationSelected: 0 },
      form7: {},
      form8: { tempEffectDuration: 1 },
      form9: { frequencyDuration: 99 },
      form10: { duration: 0 },
      formData: { modalSelected: 0 },
      pointOptions: [],
      point1Options: [],
      point2Options: [],
      point3Options: [],
      typeOptions: [],
      targetOptions: [],
      dataTypeOpitons: [
        { id: 1, name: "静态效应" },
        { id: 2, name: "动态效应" },
        { id: 3, name: "振动响应" },
      ],
      computeOptions: [
        { id: 1, name: "挠度修正" },
        { id: 2, name: "应变花(45°)" },
      ],
      preMethodOptions: [
        { id: 1, name: "previous" },
        { id: 2, name: "next" },
        { id: 3, name: "nearest" },
        { id: 4, name: "linear" },
        { id: 5, name: "spline" },
        { id: 6, name: "pchip" },
        { id: 7, name: "makima" },
        { id: 8, name: "movmean" },
        { id: 9, name: "movmedian" },
      ],
      methodOptions: [
        { id: 1, name: "矩形窗" },
        { id: 2, name: "低通滤波" },
        { id: 3, name: "陷波滤波" },
      ],
      timeOptions: [
        { id: 0, name: "其他" },
        { id: 1, name: "1分钟" },
        { id: 2, name: "5分钟" },
        { id: 3, name: "10分钟" },
        { id: 4, name: "15分钟" },
        { id: 5, name: "20分钟" },
        { id: 6, name: "0.5小时" },
        { id: 7, name: "1小时" },
        { id: 8, name: "1.5小时" },
        { id: 99, name: "不分析" },
      ],
      timeOptions2: [
        { id: 1, name: "3小时" },
        { id: 2, name: "6小时" },
        { id: 8, name: "12小时" },
        { id: 99, name: "不分析" },
      ],
      timeOptions3: [
        { id: 0, name: "不分析" },
        { id: 1, name: "多项式" },
        { id: 2, name: "指数型" },
      ],
      durationOptions: [
        { id: 0, name: "不预警" },
        { id: 1, name: "预警" },
      ],
      accuracyOptions: [
        { id: "0", name: "整数" },
        { id: "1", name: "精确1位" },
        { id: "2", name: "精确2位" },
        { id: "3", name: "精确3位" },
        { id: "4", name: "精确4位" },
        { id: "5", name: "精确5位" },
      ],
      rule1: {
        code: [{ required: true, message: "请输入测点编号", trigger: "blur" }],
        staticDynamicSelected: [{ required: true, message: "请选择数据类型", trigger: "change" }],
        // site: [{ required: true, message: "请输入位置描述", trigger: "blur" }],
        frequency: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        // model: [{ required: true, message: "请输入传感器型号", trigger: "blur" }],
        // coefficient: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        // precisions: [{ required: true, message: "请输入", trigger: "blur" }],
        // serialNumber: [{ validator: checkedSerialNumber, trigger: "blur" }],
        selectedRel: [{ required: true, message: "请选择自选状态", trigger: "change" }],
        // precisions: [{ required: true, message: "请选择精度", trigger: "change" }],
        // systemId: [{ required: true, message: "请选择所属系统", trigger: "change" }],
        contentId: [{ required: true, message: "请选择监测内容", trigger: "change" }],

        typeGroupId: [{ required: true, message: "请选择监测类型", trigger: "change" }],
        typeId: [{ required: true, message: "请选择", trigger: "change" }],
        serialNumber: [{ required: true, message: "请输入", trigger: "blur" }],
        // healthCoefficient: [{ required: true, trigger: "blur", validator: checkedCoeffici }], ///    validator: checkedCoeffici
        createTimeStr: [{ type: "date", required: true, message: "请选择日期", trigger: "change" }],
        // siteGroup: [{ required: true, message: "请输入位置分组", trigger: "blur" }],
      },
      ruleFormRules: {
        imagesId: [{ required: true, trigger: "blur", validator: checkUpload }],
      },
      ruleDerive: {
        firstTargetId: [{ required: true, message: "请选择测点", trigger: "change" }],
      },
      rule2: {
        patchDuration: [{ validator: checkedNumber, trigger: "blur" }],
      },
      rule3: {
        outlierDuration: [{ validator: checkedNumber, trigger: "blur" }],
      },
      rule4: {
        selectedArr: [{ required: true, type: "array", message: "请选择处理方法", trigger: "change" }],
        denoisingBoxWidth: [{ min: 1, type: "number", message: "窗宽度必须大于0", trigger: "blur" }],
        denoisingLowpassThreshold: [{ validator: checkedNumber, trigger: "blur" }],
        denoisingNotchThreshold: [{ validator: checkedNumber, trigger: "blur" }],
        denoisingDuration: [{ validator: checkedNumber, trigger: "blur" }],
      },
      rule5: {
        baselineRatio: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        baselineCutoffFrequency: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        baselineDuration: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        baselinePeriod: [
          { required: true, message: "时间间隔不能为空" },
          { type: "number", message: "时间间隔必须为数字值" },
        ],
        baselineType: [
          {
            required: true,
            message: "请选择类别参数",
            trigger: ["blur", "change"],
          },
        ],
      },
      rule6: {
        staticDynamicDuration: [{ validator: checkedNumber, trigger: "blur" }],
        staticDynamicCutoffFrequency: [{ validator: checkedNumber, trigger: "blur" }],
      },
      rule7: {
        timePeriod: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" },
        ],
        timeDuration: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" },
        ],
      },
      rule8: {
        timePeriod: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        frequencyDurationOther: [{ validator: checkedNumber, trigger: "blur" }],
        tempEffectAnalyseDuration: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" },
        ],
        tempEffectIsolation: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        tempEffectInterval: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" },
        ],
      },
      rule9: {
        timePeriod: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        frequencyDurationOther: [{ validator: checkedNumber, trigger: "blur" }],
        tempEffectAnalyseDuration: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        tempEffectIsolation: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        tempEffectInterval: [{ required: true, validator: checkedNumber, trigger: "blur" }],
      },
      rule10: {
        clower: [{ required: true, validator: checkedForwardLowerLimit, trigger: "blur" }],
        cupper: [{ validator: checkedForwardYellowUpperLimit, trigger: "blur" }],
        bupper: [{ validator: checkedForwardOrangeUpperLimit, trigger: "blur" }],
        flower: [{ required: true, validator: checkedNegativeYellowLowerLimit, trigger: "blur" }],
        fupper: [{ validator: checkedNegativeYellowUpperLimit, trigger: "blur" }],
        elower: [{ required: true, validator: checkedNegativeOrangeLowerLimit, trigger: "blur" }],
      },
      rule11: {
        modalTimeLag: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        modalNMin: [{ validator: checkedMinNumb, trigger: "blur" }],
        modalNMax: [{ validator: checkedMaxNumb, trigger: "blur" }],
      },
      trendRule: {
        trendOrder: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 1, type: "number", message: "阶数为1～4", trigger: "blur" },
          { max: 4, type: "number", message: "阶数为1～4", trigger: "blur" },
        ],
        trendDuration: [{ required: true, validator: checkedTrendDuration, trigger: "blur" }],
        trendPeriod: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        trendNPoint: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 1, type: "number", message: "请输入大于0的整数", trigger: "blur" },
        ],
        trendInterval: [{ required: true, validator: checkedNumber, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.targetId = this.$route.query.id;
    this.projectId = Number(this.$store.state.projectId);
    this.currentPage = this.$route.query.currentPage;
    // 上传  header头， 测点上传地址
    this.uploadHeaders = { token: window.sessionStorage.getItem("token") };
    this.upBridgeImgUrl = this.baseURL + "sys/sysImages/save/target";

    this.typeGroupId = Number(this.$route.query.typeGroupId) || "";
    this.typeId = Number(this.$route.query.typeId) || "";
    this.code = this.$route.query.code || "";

    // var projectId = sessionStorage.getItem('projectId');
    // if(projectId){
    //   this.projectId = projectId;
    // }

    let name = "";

   
    this.sysData(()=>{
      if (this.$route.query.type != "edit") {
      this.getPointTypes();
      name = "添加测点";
    } else {
      name = "编辑测点";
      this.isFirstEdit = 1;
      this.getForm1Data();
    }
    }); //系统下拉

    let arr = this.$store.state.breadcrumbList;
    if (arr.length < 4) {
      arr.push({ name });
      this.$store.commit("getBreadcrumb", arr);
    }
  },
  beforeDestroy() {
    let arr = this.$store.state.breadcrumbList;
    arr.splice(3);
    this.$store.commit("getBreadcrumb", arr);
  },
  methods: {
    getAccuracyOptions() {},
    getHealthOptions() {},
    changeTime() {},

    // 选择xyz
    changexyz() {
      this.$forceUpdate();
    },
    changeType() {
      this.$forceUpdate();
    },
    selectSys() {
      this.contentList = [];
      if (this.form1.typeId || this.form1.systemId) {
        this.getContentList();
      }
    },
    //获取内容下拉
    getContentList() {
      var systemId = ""
      var fitem = this.sysList.find(item=>{
        return item.id == this.form1.systemId
      })
      if(fitem){
        systemId = fitem.pid
      }
      this.$axios
        .get(`${this.baseURL}base/baseTarget/content`, {
          params: {
            projectId: this.projectId,
            systemId: systemId,
            typeId: this.form1.typeId || "",
          },
        })
        .then((res) => {
          this.contentList = res.data.data;
          if (this.contentList.length > 0 ) {
            if(this.$route.query.type != "edit"){
              this.form1.contentId = this.contentList[0].id;
            }
          } else{
            this.form1.contentId = ""
          }
        });
    },
    // 获取系统下拉
    sysData(callback) {
      this.$axios.get(`${this.baseURL}base/baseTarget/part/${this.projectId}`).then((res) => {

        this.sysList = res.data.data;

        this.sysList.unshift({
          id:"",
          name:"全部",
          pid:""
        })
        this.form1.systemId = this.sysList[0].id;
        callback();
        // if(this.sysList &&  this.sysList.length > 0){
        //   this.form1.systemId = this.sysList[0].id;
        // }
      });
    },

    // 上传change事件
    handleAddChange(file, fileList) {
      this.bridgeImgList = fileList;
      this.$refs.ruleForm.validate((valid) => {});
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning",
      });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      this.handleAddChange(file, fileList);
    },

    // 获取桥梁图片信息
    getBridgeImg() {
      this.$axios.get(`${this.baseURL}sys/sysImages/target/${this.targetId}`).then((res) => {
        var imgList = [];
        let url = `${this.baseURL}sys/sysImages/img/${res.data.data[0].id}`;
        let obj = {
          id: res.data.data[0].id,
          response: { data: res.data.data[0].id },
          url,
        };
        imgList.push(obj);
        this.bridgeImgList = imgList;
      });
    },

    getComputeId() {
      this.$forceUpdate();
    },

    // 获取监测类型
    getPointTypes() {
      this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.typeOptions = res.data;
          this.form1.typeGroupId = this.form1.typeGroupId ? this.form1.typeGroupId : res.data[0].id;
          this.getTargetOptions();
        }
      });
    },
    // 获取监测指标
    getTargetOptions() {
      this.$axios.get(`${this.baseURL}base/baseTargetType/byGroupId?typeGroupId=${this.form1.typeGroupId}`).then((res) => {
        this.disabled1 = true;
        res = res.data;
        if (res.errCode == 200) {
          this.targetOptions = res.data;
          // 只要不是第一次进入编辑页面（isFirstEdit为0），都需要附默认值
          if (this.isFirstEdit == 0) {
            this.form1.typeId = res.data[0].id;
          }
          if (this.isFirstEdit == 1) this.isFirstEdit = 0;
          this.getPoints();
          if(this.$route.query.type != "edit") {
            this.selectSys()
          }
        }
      });
    },
    // 获取关联测点
    getPoints() {
      if (this.form1.typeId == "1008" || this.form1.typeId == "1018") {
        this.disabled1 = false;
      } else {
        this.disabled1 = true;
      }
      this.contentList = [];
      if (this.form1.typeId || this.form1.systemId) {
        this.getContentList();
      }

      this.$axios.get(`${this.baseURL}base/baseTarget/type/${this.projectId}/${this.form1.typeId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.pointOptions = res.data;
          this.point1Options = this.initOptions();
          this.point2Options = this.initOptions();
          this.point3Options = this.initOptions();
        }
      });
    },
    initOptions() {
      return JSON.parse(JSON.stringify(this.pointOptions));
    },
    selectDataType(id) {
      // 数据类型为非静态时，1：清空时域统计数据，2：数据预处理设置模块的‘是否去处异常值’只能选否
      if (id != 1) {
        this.form7 = {};
        this.form2.outlierSelected = 0;
        this.form9.frequencyDuration = 0;
      } else {
        this.form9.frequencyDuration = 99;
        this.form9.frequencyWindow = this.form9.frequencySmooth = "";
      }
    },
    // 切换衍生指标
    selectDeriveTarget(id) {
      if (id == 0) {
        this.form1.equation = this.form1.firstTargetId = this.form1.secondTargetId = this.form1.thirdTargetId = "";
      } else {
        this.deriveForm.equation = 1;
      }
    },
    selectPoint(id, tag) {
      if (tag == "one") {
        this.point2Options = this.initOptions();
        this.point3Options = this.initOptions();
        //禁用第二、三个测点里边第一个测点选中的选项
        this.point2Options.forEach((item, i) => {
          if (item.id == this.form1.thirdTargetId) item.disabled = true;
          else if (item.id == this.form1.firstTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
        this.point3Options.forEach((item, i) => {
          if (item.id == this.form1.secondTargetId) item.disabled = true;
          else if (item.id == this.form1.firstTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
      } else if (tag == "two") {
        this.point1Options = this.initOptions();
        this.point3Options = this.initOptions();
        this.point1Options.forEach((item, i) => {
          if (item.id == this.form1.secondTargetId) item.disabled = true;
          else if (item.id == this.form1.thirdTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
        this.point3Options.forEach((item, i) => {
          if (item.id == this.form1.secondTargetId) item.disabled = true;
          else if (item.id == this.form1.firstTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
      } else if (tag == "three") {
        this.point1Options = this.initOptions();
        this.point2Options = this.initOptions();
        this.point1Options.forEach((item, i) => {
          if (item.id == this.form1.secondTargetId) item.disabled = true;
          else if (item.id == this.form1.thirdTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
        this.point2Options.forEach((item, i) => {
          if (item.id == this.form1.thirdTargetId) item.disabled = true;
          else if (item.id == this.form1.firstTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
      }
    },
    selectPlolish(id) {
      this.patchMethod = id == 0 ? "" : 1;
      this.form2.patchMethod = this.patchMethod;
    },
    selectPatchMethod(id) {
      this.form2.patchMethod = id;
    },
    selectWipeOff(id) {
      if (id == 0) this.form3.outlierDuration = "";
    },
    selectDenosing(id) {
      this.form4.denoisingSelected = id;
      if (id == 0) {
        this.form4 = {
          selectedArr: [],
          denoisingSelected: 0,
          denoisingDuration: "",
          denoisingBoxSelected: 0,
          denoisingNotchSelected: 0,
          denoisingNotchThreshold: "",
          denoisingLowpassSelected: 0,
          denoisingLowpassThreshold: "",
        };
      }
    },
    selectMethods(id) {
      if (id == 1) {
        this.form4.denoisingBoxSelected = this.form4.selectedArr.indexOf(id) > -1 ? 1 : 0;
        if (!this.form4.denoisingBoxSelected) this.form4.denoisingBoxWidth = "";
      }
      if (id == 2) {
        this.form4.denoisingLowpassSelected = this.form4.selectedArr.indexOf(id) > -1 ? 1 : 0;
        if (!this.form4.denoisingLowpassSelected) this.form4.denoisingLowpassThreshold = "";
      }
      if (id == 3) {
        this.form4.denoisingNotchSelected = this.form4.selectedArr.indexOf(id) > -1 ? 1 : 0;
        if (!this.form4.denoisingNotchSelected) this.form4.denoisingNotchThreshold = "";
      }
    },
    selectTimeFrame(id, tag) {
      if (id != 9 && tag == "form9") this.form9.frequencyDurationOther = "";
    },
    getForwardData() {
      this.form10.blower = this.form10.cupper;
      this.form10.alower = this.form10.bupper;
    },
    getNegativeData() {
      this.form10.eupper = this.form10.flower;
      this.form10.dupper = this.form10.elower;
    },
    ConvertTypes(val) {
      if (val) return Number(val);
    },
    handleSave() {
      var form1IsPass = false;
      this.$refs.form1.validate((valid) => {
        form1IsPass = valid;
      });
      var ruleFormIsPass = false;
      this.$refs.ruleForm.validate((valid) => {
        ruleFormIsPass = valid;
      });
      if (!form1IsPass || !ruleFormIsPass) {
        return;
      }
      let checkedAll = 0, //已经通过校验的个数
        num = 0; //需要验证的个数+
      this.form1.typeRel = this.form1.typeRel1.toString();
      var imagesId = "";
      if (this.bridgeImgList && this.bridgeImgList.length > 0) {
        imagesId = this.bridgeImgList[0].response.data;
      }
      var imgid = { imagesId: imagesId };
      let form = { projectId: this.projectId };
      Object.assign(form, this.form1, this.deriveForm, imgid);
      if (this.targetId) {
        //编辑
        // this.$axios.put(`${this.baseURL}base/baseTarget/${this.targetId}`,
        if (this.healthAssessFlag == 1 && form.healthAssess == 0) {
          this.$axios
            .get(`${this.baseURL}base/baseTarget/assess/${this.targetId}`)
            .then((res) => {
              if (res.data.errCode == 200) {
                if (!res.data.data) {
                  this.$message.error("请取消健康评估后关闭该功能！");
                } else {
                  this.$axios.put(`${this.baseURL}base/baseTarget/v2/${this.targetId}`, this.$qs.stringify(form)).then((res) => {
                    res = res.data;
                    if (res.errCode == 200) {
                      this.$router.push({
                        path: "/devManage",
                        query: {
                          active: 1,
                          currentPage: this.currentPage,
                          typeGroupId: this.typeGroupId,
                          typeId: this.typeId,
                          code: this.code,
                        },
                      });
                    }
                  });
                }
              } else {
                this.$message({
                  type: "error",
                  message: res.data.errMsg,
                });
              }
            })
            .catch((error) => {
            });
        } else {
          this.$axios
            .put(`${this.baseURL}base/baseTarget/v2/${this.targetId}`, this.$qs.stringify(form))
            .then((res) => {
              res = res.data;
              if (res.errCode == 200) {
                this.$router.push({
                  path: "/devManage",
                  query: {
                    active: 1,
                    currentPage: this.currentPage,
                    typeGroupId: this.typeGroupId,
                    typeId: this.typeId,
                    code: this.code,
                  },
                });
              }else {
                this.$message({
                  type: "error",
                  message: res.errMsg,
                });
              }
            })
            .catch((error) => {
              
            });
        }
      } else {
        //添加
        this.$axios
          .post(`${this.baseURL}base/baseTarget/v2`, this.$qs.stringify(form))
          .then((res) => {
            res = res.data;
            if (res.errCode == 200) {
              this.$router.push({
                path: "/devManage",
                query: {
                  active: 1,
                  currentPage: this.currentPage,
                  typeGroupId: this.typeGroupId,
                  typeId: this.typeId,
                  code: this.code,
                },
              });
            }else {
                this.$message({
                  type: "error",
                  message: res.errMsg,
                });
              }
          })
          .catch((error) => {
    
          });
      }
    },
    // 编辑前获取数据
    getForm1Data() {
      this.$axios.get(`${this.baseURL}base/baseTarget/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.form1 = res.data;
          this.healthAssessFlag = res.data.healthAssess;
          this.form1.typeRel1 = [];
          this.disabled1 = true;
          if (this.form1.typeId == "1008" || this.form1.typeId == "1018") {
            //  this.disabled1 = false;
            if (res.data.typeRel && res.data.typeRel.length) {
              this.form1.typeRel1 = res.data.typeRel.split(","); //
            }
          }

          this.getBridgeImg(); //获取图片并显示
          this.getPointTypes();
          this.selectSys();
          // 衍生指标选是
          if (this.form1.derive == 1) this.getForm2Data();
          if(!this.form1.systemId){
            this.form1.systemId = ""
          }
        }
      });
      // 获取数据预处理设置、数据二次处理
      this.$axios.get(`${this.baseURL}setttings/settingsDataClean/option/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          let obj2 = {};
          obj2.patchSelected = res.data.patchSelected ? res.data.patchSelected : 0;
          obj2.patchMethod = res.data.patchMethod;
          obj2.patchDuration = res.data.patchDuration ? res.data.patchDuration : "";
          this.form2 = obj2;
          let obj3 = {};
          obj3.outlierSelected = res.data.outlierSelected ? res.data.outlierSelected : 0;
          obj3.outlierDuration = res.data.outlierDuration ? res.data.outlierDuration : "";
          this.form3 = obj3;
          let obj5 = {};
          obj5.baselineSelected = res.data.baselineSelected ? res.data.baselineSelected : 0;
          obj5.baselineType = res.data.baselineType ? res.data.baselineType : 1;
          obj5.baselinePeriod = res.data.baselinePeriod ? res.data.baselinePeriod : "";
          obj5.baselineDuration = res.data.baselineDuration ? res.data.baselineDuration : "";
          obj5.baselineRatio = res.data.baselineRatio ? res.data.baselineRatio : "";
          obj5.baselineCutoffFrequency = res.data.baselineCutoffFrequency ? res.data.baselineCutoffFrequency : "";
          this.form5 = obj5;
          let obj6 = {};
          obj6.staticDynamicSeparationSelected = res.data.staticDynamicSeparationSelected ? res.data.staticDynamicSeparationSelected : 0;
          obj6.staticDynamicDuration = res.data.staticDynamicDuration ? res.data.staticDynamicDuration : "";
          obj6.staticDynamicCutoffFrequency = res.data.staticDynamicCutoffFrequency ? res.data.staticDynamicCutoffFrequency : "";
          this.form6 = obj6;
          let obj4 = {};
          obj4.denoisingBoxSelected = res.data.denoisingBoxSelected;
          obj4.denoisingNotchSelected = res.data.denoisingNotchSelected;
          obj4.denoisingLowpassSelected = res.data.denoisingLowpassSelected;
          obj4.denoisingSelected = res.data.denoisingSelected == 1 ? res.data.denoisingSelected : 0;
          obj4.denoisingBoxWidth = res.data.denoisingBoxWidth ? res.data.denoisingBoxWidth : "";
          obj4.denoisingLowpassThreshold = res.data.denoisingLowpassThreshold ? res.data.denoisingLowpassThreshold : "";
          obj4.denoisingNotchThreshold = res.data.denoisingNotchThreshold ? res.data.denoisingNotchThreshold : "";
          obj4.denoisingDuration = res.data.denoisingDuration ? res.data.denoisingDuration : "";
          obj4.selectedArr = [];
          this.form4 = obj4;
          res.data.denoisingBoxSelected == 1 ? this.form4.selectedArr.push(this.methodOptions[0].id) : "";
          res.data.denoisingLowpassSelected == 1 ? this.form4.selectedArr.push(this.methodOptions[1].id) : "";
          res.data.denoisingNotchSelected == 1 ? this.form4.selectedArr.push(this.methodOptions[2].id) : "";
        }
      });
      // 获取数据分析处理设置
      this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/option/${this.targetId}`).then((res) => {
        res = res.data;
        delete res.data.target;
        if (res.errCode == 200) {
          this.formData.modalSelected = res.data.modalSelected;
          let obj7 = {};
          obj7.timePeriod = res.data.timePeriod ? res.data.timePeriod : "";
          obj7.timeDuration = res.data.timeDuration ? res.data.timeDuration : "";
          this.form7 = obj7;
          let obj8 = {};
          obj8.tempEffectDuration = res.data.tempEffectDuration;
          obj8.tempEffectAnalyseDuration = res.data.tempEffectAnalyseDuration;
          obj8.tempEffectIsolation = res.data.tempEffectIsolation;
          obj8.tempEffectInterval = res.data.tempEffectInterval;
          this.form8 = obj8;
          let obj9 = {};
          obj9.frequencyDuration = res.data.frequencyDuration ? res.data.frequencyDuration : 99;
          obj9.frequencyDurationOther = res.data.frequencyDurationOther ? res.data.frequencyDurationOther : "";
          obj9.frequencyWindow = res.data.frequencyWindow ? res.data.frequencyWindow : "";
          obj9.frequencySmooth = res.data.frequencySmooth ? res.data.frequencySmooth : "";
          this.form9 = obj9;
          let trendObj = {};
          trendObj.trendType = res.data.trendType ? res.data.trendType : 0;
          trendObj.trendOrder = res.data.trendOrder ? res.data.trendOrder : "";
          trendObj.trendDuration = res.data.trendDuration ? res.data.trendDuration : "";
          trendObj.trendPeriod = res.data.trendPeriod ? res.data.trendPeriod : "";
          trendObj.trendNPoint = res.data.trendNPoint ? res.data.trendNPoint : "";
          trendObj.trendInterval = res.data.trendInterval ? res.data.trendInterval : "";
          this.trendForm = trendObj;
        }
      });
      // 获取单指标阈值处理设置
      this.$axios.get(`${this.baseURL}setttings/settingsThresholdQuota/option/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) this.form10 = res.data;
      });
    },
    getForm2Data() {
      // 获取衍生指标
      this.$axios.get(`${this.baseURL}setttings/settingsDataDerivation/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) this.deriveForm = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  // border: 1px solid rgba(151, 151, 151, 0.29) !important;
}

.derive,
.multi-form {
  &::v-deep .el-form-item__error {
    top: 38px;
  }
}

.trendForm::v-deep {
  .el-form-item {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1750px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      // width: 188px;
    }

    .el-form-item__label {
      width: 152px;
    }

    .multi-form .el-form {
      padding: 0 1% 0 6.47%;
    }

    .analyse .el-form {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 198px;
        }
      }

      .interval {
        margin-left: 100px;
      }

      .el-form-item__label {
        width: 100px;
      }
    }

    .rehandle {
      .el-form-item {
        margin-right: 2%;
      }

      .el-form-item:not(:first-child) {
        .el-form-item__label {
          width: 70px;
        }
      }
    }

    .limit-value {
      .el-form-item {
        width: 258px;

        .el-input,
        .el-input__inner {
          width: 130px;
        }
      }
    }
  }
}

@media screen and (max-width: 1750px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 127px;
    }

    .el-form:first-child {
      .el-form-item__label {
        min-width: 122px;
        white-space: nowrap;
      }
    }

    .derive {
      .el-form-item__label {
        min-width: 110px;
        white-space: nowrap;
      }

      .el-input,
      .el-input__inner {
        width: 183px;
      }
    }

    .multi-form .el-form {
      padding-left: 13px;
    }

    .analyse section {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 127px;
        }
      }

      .el-form-item:nth-child(2) {
        margin-left: 15px;
      }

      .interval {
        margin-left: 80px;
      }
    }

    .rehandle {
      .el-form-item:not(:first-child) {
        .el-form-item__label {
          width: 72px;
        }
      }

      .el-form-item {
        margin-right: 20px !important;
      }
    }

    .limit-value {
      .el-form-item {
        width: 220px;

        .el-form-item__label {
          margin-right: 10px !important;
        }

        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }

      .el-form-item:nth-child(odd) {
        width: 220px;

        .el-form-item__label {
          width: 86px !important;
        }
      }
    }
  }
}

.time_length::v-deep .el-form-item__label {
  margin-left: 40px;
}

.el-form::v-deep {
  .el-form-item__label {
    padding: 0;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background: #0c265a;
  }

  .red-warning .el-form-item__label {
    text-align: right;
  }

  .el-form-item__content {
    height: 52px;
    width: auto;
    line-height: 52px;
    text-align: center;
  }

  .el-input__inner {
    padding-left: 30px;
    height: 30px;
    line-height: 30px;
    // text-align: center;
  }
  .install-time-warp {
    .el-input__inner {
      padding-left: 30px;
      height: 30px;
      line-height: 30px;
      // text-align: center;
    }
  }

  .el-select {
    .el-input__inner {
      text-align: left;
      padding-left: 13px;
      border: 1px solid rgba(151, 151, 151, 0.29);
    }

    .el-input__icon {
      height: 30px;
      line-height: 30px;
    }
  }

  .el-input.is-disabled .el-input__inner {
    border: 1px solid #383e41 !important;
    background: rgba(56, 63, 65, 0.29) !important;
  }

  .el-form-item.long-input {
    width: 50% !important;

    .el-input,
    .el-input__inner {
      // width: 95% !important;
    }
  }
}

p {
  color: #fff;
  line-height: 40px;
  text-align: center;
}

.el-form-item {
  margin: 0;
  border-top: 1px solid #2c3134;
}

.el-form-item {
  display: inline-flex;

  &::v-deep {
    .el-form-item__content {
      flex: 1;
    }
  }
}

.title {
  // width: 152px;
  display: flex;
  word-wrap: none;
  white-space: nowrap;
  color: #fff;
  align-items: center;
  justify-content: center;
  // background: #0c265a;
}

.max_factorial {
  margin-left: 20px;
}

.denosing-form .title {
  border-bottom: 1px solid #2c3134;
}

.derive {
  &::v-deep {
    .el-form-item__label,
    .el-col {
      margin-right: 22px;
    }
  }
}

.multi-form,
.el-form:first-of-type,
.el-form:nth-of-type(2) {
  margin-top: 20px;
  // border-radius: 8px;
  margin-bottom: 26px;
  // border: 1px solid #5E5E5F;
  // box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  background: #05193f;
}

.el-form:first-child {
  &::v-deep {
    .el-form-item__content > div:not(.el-select) {
      .el-input__inner {
        padding-left: 25px;
        border: none;
      }
    }
  }

  .el-form-item {
    width: 50%; //25
  }

  .el-form-item:nth-of-type(5) {
    //width: 50%;
  }

  .el-form-item:nth-of-type(6) {
    //width: 50%;
    //padding-right: 42px;
  }

  .el-form-item:nth-last-of-type(1) {
    width: 50%;
  }
}

.el-form:nth-child(2) {
  .el-form-item {
    display: block;

    &::v-deep {
      .el-form-item__content {
        display: inline-flex;
      }
    }

    .el-select:not(:last-child) {
      margin-right: 22px;
    }
  }
}

.multi-form {
  section {
    display: flex;
    border-top: 1px solid #2c3134;

    p {
      width: 152px;
      height: 52px;
      line-height: 52px;
      background: #0c265a;
    }

    .el-form {
      flex: 1;
      margin: 0;
      border: none;
      display: flex;
      flex-wrap: wrap;
      box-shadow: none;

      .el-form-item {
        border: none;
        height: 52px;

        &::v-deep {
          .el-form-item__label {
            text-align: left;
            margin-right: 20px;
            white-space: nowrap;
            background: transparent;
          }
        }

        .unit {
          padding: 0 12px;
          white-space: nowrap;
        }
      }
    }
  }

  .denosing-form {
    .el-form {
      margin: 0;
      border-radius: 0;
      border-bottom: 1px solid #2c3134;
    }

    .el-form-item:nth-of-type(2) {
      width: 50%;

      &::v-deep {
        .el-form-item__content {
          white-space: nowrap;
        }
      }
    }

    .el-form-item {
      &::v-deep .el-form-item__error {
        top: 75% !important;
      }
    }
  }

  .rehandle {
    .el-form-item:first-child {
      &::v-deep .el-form-item__label {
        width: 182px;
      }
    }
  }
}

.multi-form:nth-of-type(-n + 1) {
  .el-form-item {
    margin-right: 4%;
  }
}

.set-time {
  padding: 0 2% !important;

  .el-form-item:first-child {
    margin-right: 20px;
  }
}

.set-threshold {
  .title {
    border: none;
  }

  .form-title {
    margin: 20px 0;

    span {
      width: 50%;
      display: inline-block;
    }
  }

  .form-list {
    flex: 1;
    padding: 0 0 23px 2%;

    .el-form {
      padding: 0;

      &::v-deep {
        .el-form-item__content {
          width: 78px;
        }

        .el-form-item__label {
          padding: 0;
          width: 90px;
        }
      }
    }

    .limit-value {
      width: 50%;

      .el-form-item {
        display: inline-flex;
      }
    }
  }
}

.btns {
  margin-top: 44px;
  text-align: center;

  .el-button {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }

  .el-button:last-child {
    margin-left: 50px;
    background: #1e2529;
  }
}

::v-deep .el-form {
  .el-upload-list--picture-card .el-upload-list__item-status-label {
    background: #2772f0;
  }

  .formMain {
    // border: 1px solid #5E5E5F;
    // box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
    background: #05193f;
    border: 1px solid #0c3380;
    .title {
      color: #fff;
      line-height: 40px;
      text-align: center;
    }

    .el-form-item {
      width: 50%;
      min-height: 52px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      border-top: 1px solid rgba(59, 104, 169, 0.48);
      border-right: 1px solid rgba(59, 104, 169, 0.48);
      &.borderbot {
        border-bottom: 1px solid #5e5e5f;
      }

      &.cityItem {
        .el-form-item__content {
          .el-select {
            height: 31px;
            width: 40%;

            .el-input {
              width: 100%;
              border-radius: 4px;
              // border: 1px solid rgba(151, 151, 151, 0.29);
              line-height: 31px;
            }
          }
        }
      }

      &.docItemMain {
        min-height: 108px;
        position: relative;
        width: 100%;

        .el-form-item {
          border: none;
        }

        .el-form-item__label {
          height: 100%;
          position: absolute;
          min-width: 122px;
        }

        .el-form-item__content {
          line-height: 0;
          // padding-left: 216px;

          & > div {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: flex-end;
            width: 100%;
          }

          .el-upload-list--picture-card {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex: 1;

            .el-upload-list__item {
              width: 80px;
              height: 60px;
              margin: 20px 0 20px 40px;
              border: none;
            }
          }

          .el-upload--picture-card {
            width: 80px;
            height: 60px;
            // background: #21272b;
            background: #0a1f4a;

            border-radius: 2px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 40px;

            i {
              color: #3d6391;
            }
          }

          div.hideUpload {
            .el-upload--picture-card {
              display: none;
            }
          }
        }
      }

      .el-form-item__label {
        // width: 216px;
        height: 100%;
        background: #0c265a;
        // border-radius: 0px 0px 0px 8px;
        padding: 0;
        text-align: center;
        line-height: 52px;

        &.requiredTip:before {
          content: "*";
          color: #f56c6c;
          margin-right: 4px;
        }
      }

      .el-form-item__content {
        flex: 1;
        display: flex;
        align-items: center;
        // justify-content: center;

        .el-select {
          .el-input {
            width: 178px;
            height: 31px;
            border-radius: 4px;
            // border: 1px solid rgba(151, 151, 151, 0.29);
            line-height: 31px;
          }
        }

        .el-input {
          // width: 100%;
          height: 52px;
          width: 260px;
          border: none;

          .el-input__inner {
            border: none;
            // text-align: center;
          }

          .el-input__icon {
            line-height: 32px;
          }
        }

        .el-checkbox__inner {
          width: 20px;
          height: 20px;

          &:after {
            height: 9px;
            left: 6px;
            width: 5px;
          }
        }

        .el-form-item__error {
          left: 48px;
          text-align: right;
          margin-left: 300px;
        }

        .tips {
          color: #f0f0f0;
          padding-left: 30px;
        }
      }
    }
  }
}

::v-deep .long-input1 .el-input,
::v-deep .long-input1 .el-input .el-input__inner {
  width: 100% !important;
}

.formMain-title {
  display: flex;
  align-items: center;
  padding: 16px 0 23px 16px;
}
.formMain-content {
  margin: 0 30px 20px 43px;
  border-left: 1px solid rgba(59, 104, 169, 0.48);
  border-bottom: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep .el-form-item__error {
  right: 5px !important;
  top: auto;
  left: auto;
}
::v-deep .el-form-item__label {
  // border-top: 1px solid rgba(59, 104, 169, 0.48);
  border-right: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep .el-form-item__content {
  background-color: #0a1a42;
  // border-top: 1px solid rgba(59, 104, 169, 0.48);
  // border-right: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep .selectItem .el-input .el-input__inner {
  background: #081b42 !important;
}
::v-deep .el-input .el-input__inner {
  background-color: #0a1a42 !important;
}

::v-deep .jungongTime .el-input__prefix {
  left: auto;
  right: 20px;
}
::v-deep .jungongTime .el-input__inner {
  padding-left: 15px;
}

::v-deep .el-form-item__content .el-cascader {
  width: 262px;
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
  padding-left: 13px;
}
::v-deep .el-form-item__content .el-cascader .el-input {
  border: 1px solid rgba(151, 151, 151, 0.29) !important;
  height: 32px !important;
  line-height: 32px;
  position: relative;
}
::v-deep .el-form-item__content .el-cascader .el-cascader__tags {
  width: 200px;
  left: 15px;
}
::v-deep .el-form-item__content .el-cascader .el-input .el-input__inner {
  height: 28px !important;
}

::v-deep .border-item {
  border-bottom: 1px solid rgba(59, 104, 169, 0.48);
  position: relative;
  top: 1px;
}

::v-deep .img-box .el-form-item {
  width: 248px !important;
  display: inline-block !important;
  .upload-demo {
    margin: 0;
    padding-left: 28px;
  }
}
::v-deep .img-box .el-form-item__error {
  width: 102px !important;
  position: absolute;
  right: 0px !important;
  display: flex;
  justify-content: flex-end;
}

::v-deep .danwei-item {
  position: relative;
}
::v-deep .danwei-item .el-form-item {
  width: 100% !important;
}

::v-deep .danwei-item .el-form-item__content {
  width: 100%;
}

::v-deep .danwei-item .el-form-item__error {
  position: absolute;
  right: 5px !important;
}
::v-deep .system-item .el-form-item__content {
  width: 200px !important;
}
::v-deep .system-item .model_upload {
  width: 200px !important;
}
::v-deep .el-form .formMain .el-form-item .el-form-item__content .el-select .el-input {
  width: 250px;
  height: 32px;
  border-radius: 0px;
  border: 1px solid rgba(151, 151, 151, 0.29);
  line-height: 28px;
  margin-left: 13px;
}
::v-deep .anzhuang-item .el-input__prefix {
  left: auto;
  display: none;
}
::v-deep .anzhuang-item .el-form-item__content {
  // border-bottom: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep .img-item {
  width: calc(100% - 154px) !important;
  position: relative;
  left: 152px;
  background: #05193f !important;
}

::v-deep .img-item .el-form-item {
  width: 200px !important;
}
::v-deep .img-item .el-upload-list {
  display: flex;
  width: 500px !important;
}
// el-upload-list
::v-deep .img-item .el-form-item__content {
  background: #05193f !important;
  position: relative;
  left: 2px;
}
.formMain-title {
  display: flex;
  align-items: center;
  padding: 16px 0 23px 16px;
}
.formMain-content {
  margin: 0 30px 20px 43px;
  border-left: 1px solid rgba(59, 104, 169, 0.48);
  border-bottom: 1px solid rgba(59, 104, 169, 0.48);
}

.tooltip11{
    position: absolute;
    left: -30px;
    width: 14px;
    height: 14px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('../../../assets/images/tipoff.png');
    background-size: 100% 100%;
    &:hover{
      background-image: url('../../../assets/images/tipon.png');
    }
  }
</style>
